import { RequestManager } from "utils";
import config from "configs";

const connects = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/connects`,
    params
  );
};

const search = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/connects/search`,
    params
  );
};

const exported = {
  connects,
  search,
};

export default exported;
