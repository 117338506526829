import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Card, Form, Input, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import SettingApi from "apis/SettingApi";
import CommonSlice from "stores/CommonSlice";

export default function RefSetting() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { setting } = useAppSelector((state) => state.setting);
  const newUser = setting.find(
    (item) => item.key === "new_token_referral_bonus"
  );
  const activeUser = setting.find(
    (item) => item.key === "active_token_referral_bonus"
  );
  const refDonateBonus = setting.find(
    (item) => item.key === "token_referral_donate_bonus"
  );
  const donateBonus = setting.find((item) => item.key === "token_donate_bonus");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (setting) {
      form.setFieldsValue({
        new_token_referral_bonus: newUser?.value,
        active_token_referral_bonus: activeUser?.value,
        token_referral_donate_bonus: refDonateBonus?.value,
        token_donate_bonus: donateBonus?.value,
      });
    }
  }, [setting]);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const new_token_referral_bonus = {
        key: "new_token_referral_bonus",
        value: parseFloat(values.new_token_referral_bonus),
      };
      const active_token_referral_bonus = {
        key: "active_token_referral_bonus",
        value: parseFloat(values.active_token_referral_bonus),
      };
      const token_referral_donate_bonus = {
        key: "token_referral_donate_bonus",
        value: parseFloat(values.token_referral_donate_bonus),
      };
      const token_donate_bonus = {
        key: "token_donate_bonus",
        value: parseFloat(values.token_donate_bonus),
      };
      const [result1, result2, result3, result4] = await Promise.all([
        SettingApi.updateSetting(new_token_referral_bonus),
        SettingApi.updateSetting(active_token_referral_bonus),
        SettingApi.updateSetting(token_referral_donate_bonus),
        SettingApi.updateSetting(token_donate_bonus),
      ]);
      if (result1 && result2 && result3 && result4) {
        dispatch(
          CommonSlice.actions.showNotice({
            type: "success",
            message: "Success!",
            description: "Update successful",
          })
        );
      }
    } catch (error) {
      dispatch(
        CommonSlice.actions.showNotice({
          type: "error",
          message: "Error!",
          description: "Update failed",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <Card title="Referral" bordered={false}>
      <Form form={form} name="basic" onFinish={onFinish} layout="vertical">
        <Form.Item
          label="New"
          name="new_token_referral_bonus"
          rules={[{ required: true, message: "Please input your value!" }]}
        >
          <InputNumber style={{ width: "100%" }} addonAfter="PC" />
        </Form.Item>

        <Form.Item
          label="Active"
          name="active_token_referral_bonus"
          rules={[{ required: true, message: "Please input your value!" }]}
        >
          <InputNumber style={{ width: "100%" }} addonAfter="PC" />
        </Form.Item>

        <Form.Item
          label="F1 Donate"
          name="token_referral_donate_bonus"
          rules={[{ required: true, message: "Please input your value!" }]}
        >
          <InputNumber style={{ width: "100%" }} addonAfter="PC" />
        </Form.Item>

        <Form.Item
          label="User Donate"
          name="token_donate_bonus"
          rules={[{ required: true, message: "Please input your value!" }]}
        >
          <InputNumber style={{ width: "100%" }} addonAfter="PC" />
        </Form.Item>

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
