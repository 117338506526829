import { Radio, Typography, Space, Table } from "antd";
import moment from "moment";
import FriendVolume from "pages/users/FriendVolume/FriendVolume";
import queryString from "query-string";
import { isEmpty } from "ramda";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "stores";
import { getUserHistory } from "stores/UserSlice";
import Helpers from "utils/Helpers";
import { getTypeLabel } from "utils/Mocks/Transaction";
import { orEmpty } from "utils/Selector";

const menus = [
  { key: "donate-withdraw", text: "Deposit & Withdraw" },
  { key: "coin-histories", text: "Action" },
  { key: "mines", text: "Mining" },
  { key: "referrals", text: "Reward" },
  { key: "autos", text: "Auto" },
];

export const textEllipseMiddle = (str: string) => {
  if (str && str.length > 10) {
    return str.substr(0, 5) + "..." + str.substr(str.length - 4, str.length);
  }
  return str;
};

export default function ListOptions() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [tabSelected, setTabSelected] = useState("donate-withdraw");
  const [filter, setFilter] = useState<any>(null);
  const { histories } = useAppSelector((state) => state.user);
  const query: any = useMemo(() => {
    const data: any = queryString.parse(location.search);
    return {
      type: data.type ? data.type : "donate-withdraw",
      page: data.page ? parseInt(data.page as string) : 1,
      pageSize: data.pageSize ? parseInt(data.pageSize as string) : 5,
    };
  }, [location.search]);

  const onChangeTab = (e: any) => {
    setTabSelected(e.target.value);
    navigate({
      search: queryString.stringify({ type: e.target.value }),
    });
  };

  const onChangePage = (e: any) => {
    console.log("Page: ", e);
    setFilter({
      ...filter,
      page: e,
    });
  };

  function fetchData() {
    //@ts-ignore
    const p = { ...filter };
    if (filter) {
      p.id = orEmpty("id", params);
      dispatch(getUserHistory(p));
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, params.id]);

  useEffect(() => {
    if (!isEmpty(query)) {
      setTabSelected(query.type);
    }
  }, [query]);

  useEffect(() => {
    if (JSON.stringify(filter) !== JSON.stringify(Helpers.clean(query))) {
      setFilter({ ...Helpers.clean(query) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (value: string) => `#${Helpers.shortenId(value)}`,
    },
    {
      title: "Type",
      dataIndex: "transactionType",
      key: "type",
      render: (value: string, node: TransactionTotalType) =>
        value
          ? getTypeLabel(value)
          : node.historyType
          ? getTypeLabel(node.historyType)
          : getTypeLabel(node.type),
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "time",
      render: (value: string) => moment(value).format("YYYY-MM-DD hh:mm"),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (value: number, node: TransactionTotalType) =>
        `${value} ${node.asset ? node.asset : node.coinType}`,
    },
    {
      title: filter && filter.type === "donate-withdraw" ? "Txid" : "Sender",
      dataIndex: "index",
      key: "index",
      render: (_: any, node: TransactionTotalType) =>
        filter && filter.type === "donate-withdraw" ? (
          <Typography.Text copyable={{ text: node.txId }}>
            {textEllipseMiddle(node.txId)}
          </Typography.Text>
        ) : (
          node.sender
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value: string) =>
        query.type === "coin-histories" ? "COMPLETED" : value,
    },
  ];

  return (
    <div className="form-item-container">
      <Radio.Group
        value={tabSelected}
        buttonStyle="solid"
        style={{ marginBottom: 15 }}
        onChange={onChangeTab}
      >
        <Space>
          {menus.map((item, index) => (
            <Radio.Button key={index} value={item.key}>
              {item.text}
            </Radio.Button>
          ))}
        </Space>
      </Radio.Group>
      <Table
        columns={columns}
        dataSource={histories.datas.map((item, index) => ({
          key: item.id,
          index: index,
          ...item,
        }))}
        pagination={{
          defaultPageSize: 5,
          defaultCurrent: 1,
          current: filter ? filter.page : 1,
          showSizeChanger: false,
          total: histories?.meta.total,
          onChange: (page) => onChangePage(page),
          showTotal: (total: number) => `Total: ${total}`,
        }}
      />
    </div>
  );
}
