import { RequestManager } from "utils";
import config from "configs";

const general = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/statics/general`,
    params
  );
};

const generalAdmin = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
      `${config.api_base_url}/admin/statics/admin-general`,
      params
  );
};

const dailyVolume = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/statics/daily-volume`,
    params
  );
};

const dailyActiveUser = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/statics/daily-active-user`,
    params
  );
};

const dailyRegisterUser = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
      `${config.api_base_url}/admin/statics/daily-register`,
      params
  );
};

const dailyStakingRedeem = (params: any) => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/statics/daily-redeem`,
    params
  );
};

const exported = {
  general,
  dailyVolume,
  dailyActiveUser,
  generalAdmin,
  dailyRegisterUser,
  dailyStakingRedeem
};

export default exported;
