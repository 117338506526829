import { Col, Row, Tag } from "antd";
import { useEffect, useState } from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";
import { Mocks } from "utils";

type Props = {
  onSearch: (params: any) => void;
  filter: any;
};

export default function ListFilter(props: Props) {
  const { filter } = props;
  const [listFilter, setListFilter] = useState<FilterType[]>([]);

  const checkFilter = (filterCheck: string, label: string) => {
    let newListFilter = [...listFilter];
    const indexFilter = listFilter.findIndex(
      (item) => item.value === filterCheck
    );
    if (indexFilter != -1) {
      newListFilter[indexFilter].label = label;
    }
    newListFilter.push({
      value: filterCheck,
      label: label,
    });
    setListFilter(newListFilter);
  };

  useEffect(() => {
    if (filter) {
      if (filter.isActive) {
        checkFilter(
          "withStatusActive",
          Mocks.FILTER.getLabelStatusActive(filter.isActive)
        );
      }
      if (filter.enableTwoFactor) {
        checkFilter(
          "withStatus2FA",
          Mocks.FILTER.getLabelStatus2FA(filter.enableTwoFactor)
        );
      }
      if (filter.sponsor) {
        checkFilter(
            "withSponsor",
            Mocks.FILTER.getLabelSponsor(filter.sponsor)
        );
      }
    }
  }, [filter]);

  function handleRemoveFilter(e: any, item: FilterType) {
    const defaultFilter = { ...filter };
    e.preventDefault();
    setListFilter(listFilter.filter((node) => node.value != item.value));
    switch (item.value) {
      case "withStatusActive":
        defaultFilter.isActive = null;
        props.onSearch(defaultFilter);
        return;
      case "withStatus2FA":
        defaultFilter.enableTwoFactor = null;
        props.onSearch(defaultFilter);
        return;
      case "withSponsor":
        defaultFilter.sponsor = null;
        props.onSearch(defaultFilter);
        return;
    }
  }

  return (
    <div>
      {listFilter.length ? (
        <Row style={{ margin: "15px 0" }} gutter={24}>
          <Col span={24}>
            {Helpers.getUnique(listFilter, "value").map(
              (item: FilterType, index: number) => {
                return (
                  <Tag
                    key={index}
                    closable
                    onClose={(e) => handleRemoveFilter(e, item)}
                  >
                    {item.label}
                  </Tag>
                );
              }
            )}
          </Col>
        </Row>
      ) : null}
    </div>
  );
}
