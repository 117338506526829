import {
  HomeOutlined,
  UserOutlined,
  TransactionOutlined,
  CreditCardOutlined,
  PlayCircleOutlined,
  SettingOutlined,
  ScheduleOutlined,
  HistoryOutlined,
  GiftOutlined,
  ApartmentOutlined,
} from "@ant-design/icons";
import React from "react";

const menusFull = [
  {
    key: "/general",
    icon: React.createElement(HomeOutlined),
    label: `General`,
  },
  {
    key: "/users",
    icon: React.createElement(UserOutlined),
    label: `Users`,
  },
  {
    key: "/transaction-histories",
    icon: React.createElement(TransactionOutlined),
    label: `Transaction Histories`,
  },
  {
    key: "/withdraw-requests",
    icon: React.createElement(CreditCardOutlined),
    label: `Withdraw Requests`,
  },
  {
    key: "/connects",
    icon: React.createElement(ApartmentOutlined),
    label: `Peace Connects`,
  },
  {
    key: "/settings",
    icon: React.createElement(SettingOutlined),
    label: `Setting`,
  },
];

const menusAdmin = [
  {
    key: "/general",
    icon: React.createElement(HomeOutlined),
    label: `General`,
  },
  {
    key: "/users",
    icon: React.createElement(UserOutlined),
    label: `Users`,
  },
  {
    key: "/transaction-histories",
    icon: React.createElement(TransactionOutlined),
    label: `Transaction Histories`,
  },
  {
    key: "/withdraw-requests",
    icon: React.createElement(CreditCardOutlined),
    label: `Withdraw Requests`,
  },
  {
    key: "/connects",
    icon: React.createElement(ApartmentOutlined),
    label: `Peace Connects`,
  },
  {
    key: "/settings",
    icon: React.createElement(SettingOutlined),
    label: `Setting`,
  },
];

export { menusAdmin, menusFull };
