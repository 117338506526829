import React, {useEffect, useState} from "react";
import { Column } from "@ant-design/plots";
import { useAppSelector } from "stores";
import { Typography } from "antd";

const { Title } = Typography;

export default function DailyUserRegister() {
    const { dailyRegister } = useAppSelector((state) => state.general);
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        dailyRegister.forEach((item: any) => {
            data.push({
                _id: item._id,
                user: item.totalUser,
                type: "New User"
            });
            data.push({
                _id: item._id,
                user: item.totalActiveUser,
                type: "Active User"
            });
        })
    }, [dailyRegister]);

    return (
        <div className="form-item-container">
            <div style={{ marginBottom: 30 }}>
                <Title level={5}>Daily User Register</Title>
            </div>
            {
                //@ts-ignore
                <Column {...{
                    data: data,
                    isGroup: true,
                    xField: "_id",
                    yField: "user",
                    seriesField: 'type',
                    label: {
                        position: "middle",
                        style: {
                            fill: "#FFFFFF",
                            opacity: 0.6,
                        },
                    },
                    xAxis: {
                        label: {
                            autoHide: true,
                            autoRotate: false,
                        },
                    },
                }} />
            }
        </div>
    );
}
