import Content from "./components/Content";
import Header from "./components/Header";

export default function UsersPage() {
  return (
    <div className="detail-user">
      <Header />
      <Content />
    </div>
  );
}
