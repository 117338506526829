import {
  Button,
  Descriptions,
  Divider,
  Input,
  InputNumber,
  Radio,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import { updateUser } from "stores/UserSlice";
import { Helpers } from "utils";
const { Text } = Typography;

export default function InfoUser() {
  const dispatch = useAppDispatch();
  const [activeStatus, setActiveStatus] = useState("");
  const [twofactor, setTwofactor] = useState("");
  const [email, setEmail] = useState("");
  const [rank, setRank] = useState<number>(0);
  const [password, setPassword] = useState("");
  const { detail } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (detail) {
      setActiveStatus(detail.isActive ? "active" : "deactive");
      setTwofactor(detail.enableTwoFactor ? "enable" : "disable");
      setEmail(detail.email);
      setRank(detail.userCommission.level);
    }
  }, [detail]);

  function onChangeRadio(e: any, key: string) {
    const value = e.target.value;
    switch (key) {
      case "active":
        setActiveStatus(value);
        return;
      case "twofactor":
        setTwofactor(value);
        return;
    }
  }

  const onChangeRank = (value: number | null) => {
    setRank(value || 0);
  };

  const onSave = () => {
    if (detail) {
      const params: any = {
        id: detail.id,
        email,
        isActive: activeStatus === "active" ? true : false,
        enableTwoFactor: twofactor === "enable" ? true : false,
        rank,
      };
      if (params.email === detail.email) {
        //@ts-ignore
        delete params.email;
      }
      if (password && password.length > 0) {
        params.password = password;
      }
      dispatch(updateUser(params));
    }
  };

  return (
    <div className="form-item-container">
      <Descriptions column={6} title="User Info">
        <Descriptions.Item span={6} label="Email">
          <Input value={email} onChange={(e) => setEmail(e.target.value)} />
        </Descriptions.Item>
        <Descriptions.Item span={6} label="Password">
          <Input.Password
            width={300}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Username">
          {detail?.username}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Sponsor">
          {detail?.userCommission?.sponsor
            ? detail?.userCommission?.sponsor.username
            : "..."}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Status">
          {detail?.status}
        </Descriptions.Item>
        <Descriptions.Item span={6} label="BSC Address">
          <Text copyable={true}>{detail?.bscWalletAddress}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={6} label="TRX Address">
          <Text copyable={true}>{detail?.tronWalletAddress}</Text>
        </Descriptions.Item>

        <Descriptions.Item span={2} label="Total Deposit">
          {Helpers.checkInt(detail?.userCoin.totalDepositBalance || 0)} USDT
        </Descriptions.Item>
        <Descriptions.Item span={4} label="Total Withdraw">
          {Helpers.checkInt(detail?.userCoin.totalWithdrawBalance || 0)} USDT
        </Descriptions.Item>

        <Descriptions.Item span={2} label="Total F1">
          {Helpers.checkInt(detail?.userCommission.totalF1 || 0)}
        </Descriptions.Item>
        <Descriptions.Item span={4} label="Total F1 Active">
          {Helpers.checkInt(detail?.userCommission.totalF1Active || 0)}
        </Descriptions.Item>

        <Descriptions.Item span={2} label="USDT Balance">
          {detail?.userCoin.balance} USDT
        </Descriptions.Item>
        <Descriptions.Item span={2} label="PC Balance">
          {detail?.userCoin.tokenBalance} PC
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Frozen Balance (Lock)">
          {detail?.userCoin.balanceFrozen} USDT
        </Descriptions.Item>

        <Descriptions.Item span={2} label="Total Commission">
          {Helpers.checkInt(detail?.userCoin.totalCommissionBalance || 0)} USDT
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Total Referral Commission">
          {Helpers.checkInt(
            detail?.userCoin.totalReferralCommissionBalance || 0
          )}{" "}
          USDT
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Total Auto Commission">
          {Helpers.checkInt(detail?.userCoin.totalAutoCommissionBalance || 0)}{" "}
          USDT
        </Descriptions.Item>

        <Descriptions.Item span={2} label="Total Commission Token">
          {Helpers.checkInt(detail?.userCoin.totalCommissionToken || 0)} PC
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Total Mining Token">
          {Helpers.checkInt(detail?.userCoin.totalMiningToken || 0)} PC
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Total Giver">
          {Helpers.checkInt(detail?.userCoin.totalGiveToken || 0)} USDT
        </Descriptions.Item>
      </Descriptions>
      <Divider className="mx-10" />
      <Descriptions column={6}>
        <Descriptions.Item span={3} label="Status active">
          <Radio.Group
            value={activeStatus}
            onChange={(e) => onChangeRadio(e, "active")}
          >
            <Radio value={"active"}>Active</Radio>
            <Radio value={"deactive"}>Deactive</Radio>
          </Radio.Group>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Two Factor">
          <Radio.Group
            value={twofactor}
            onChange={(e) => onChangeRadio(e, "twofactor")}
          >
            <Radio value={"enable"}>Enable</Radio>
            <Radio value={"disable"}>Disable</Radio>
          </Radio.Group>
        </Descriptions.Item>
      </Descriptions>
      <div style={{ textAlign: "right" }}>
        <Button onClick={() => onSave()} type="primary" size="large">
          Save
        </Button>
      </div>
    </div>
  );
}
