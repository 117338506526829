export const filterOptions = [
  {
    label: "Status",
    value: "withStatus",
  },
  {
    label: "Network",
    value: "withNetwork",
  },
];

export const optionMultiple = [] as string[];

export const UserCoinTransactionStatus = [
  {
    label: "WAIT ADMIN CONFIRM",
    value: "WAIT_ADMIN_CONFIRM",
  },
  {
    label: "PENDING",
    value: "PENDING",
  },
  {
    label: "WAIT NETWORK CONFIRM",
    value: "WAIT_NETWORK_CONFIRM",
  },
  {
    label: "CANCELLED",
    value: "CANCELLED",
  },
  {
    label: "SUCCESSFUL",
    value: "SUCCESSFUL",
  },
  {
    label: "FAILED",
    value: "FAILED",
  },
];

export const Network = [
  {
    label: "ETH",
    value: "ETH",
  },
  {
    label: "BSC",
    value: "BSC",
  },
  {
    label: "TRX",
    value: "TRX",
  },
  {
    label: "NEAR",
    value: "NEAR",
  },
  {
    label: "UNKNOWN",
    value: "UNKNOWN",
  },
];

const types = [
  {
    value: "MINING",
    label: "Mining",
  },
  {
    value: "DONATE_BONUS",
    label: "Donate Bonus",
  },
  {
    value: "DEPOSIT",
    label: "Deposit",
  },
  {
    value: "WITHDRAW",
    label: "Withdraw",
  },
  {
    value: "REFERRAL",
    label: "Referral Donate",
  },
  {
    value: "REFERRAL_BONUS",
    label: "Referral Bonus",
  },
  {
    value: "REFERRAL_DONATE_BONUS",
    label: "Referral Donate",
  },
  {
    value: "TREE_BONUS",
    label: "Donate Bonus",
  },
  {
    value: "GIVER",
    label: "Giver",
  },
  {
    value: "RANK_BONUS",
    label: "Badge",
  },
  {
    value: "EVENT",
    label: "Event",
  },
  {
    value: "DONATE",
    label: "Donate",
  },
  {
    value: "BUY_NFT",
    label: "Mint NFT",
  },
  { value: "NFT_BONUS", label: "NFT Bonus" },
  { value: "REFERRAL_NFT_BONUS", label: "Referral NFT" },
];

export const getTypeLabel = (value: string) => {
  const item = types.find((item) => item.value === value);
  if (item) {
    return item.label;
  }
  return "";
};
