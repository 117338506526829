import { RequestManager } from "utils";
import config from "configs";

const setting = () => {
  return RequestManager.v1.withAuthorize.get(
    `${config.api_base_url}/admin/settings/`
  );
};

const updateSetting = (params: any) => {
  const { key, ...otherParams } = params;
  return RequestManager.v1.withAuthorize.put(
    `${config.api_base_url}/admin/settings/${key}`,
    otherParams
  );
};

const exported = {
  setting,
  updateSetting,
};

export default exported;
