import { Descriptions, Typography } from "antd";
import React from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";

const { Text } = Typography;

export default function GeneralView() {
  const { general  } = useAppSelector((state) => state.general);
  return (
    <div className="form-item-container">
      <Descriptions title="User Statistics">
        <Descriptions.Item label="Total Withdraw">
          <Text strong>{Helpers.checkInt(general?.totalWithdraw || 0)} USDT</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Total Donate">
          <Text strong>{Helpers.checkInt(general?.totalDeposit || 0)} USDT</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Total Commisson">
          <Text strong>{Helpers.checkInt(general?.totalUSDTReferralBonus || 0)} USDT</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Total Donate Bonus">
          <Text strong>{Helpers.checkInt(general?.totalTokenDonateBonus || 0)} PC</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Total Token Mining">
          <Text strong>{Helpers.checkInt(general?.totalTokenMining || 0)} PC</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Total Token Commisson">
          <Text strong>{Helpers.checkInt(general?.totalPCReferralBonus || 0)} PC</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Total Balance">
          <Text strong>{Helpers.checkInt(general?.totalBalance || 0)} USDT</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Total Frozen">
          <Text strong>{Helpers.checkInt(general?.totalBalanceFrozen || 0)} USDT</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Total Token Balance">
          <Text strong>{Helpers.checkInt(general?.totalTokenBalance || 0)} PC</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Total User">
          <Text strong>{Helpers.checkInt(general?.totalUser || 0)}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Total ACTIVE User">
          <Text strong>{Helpers.checkInt(general?.totalActiveUser || 0)}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Total NEW User">
          <Text strong>{Helpers.checkInt(general?.totalNewUser || 0)}</Text>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
