import React, {useEffect, useState} from "react";
import { Column } from "@ant-design/plots";
import { useAppSelector } from "stores";
import { Typography } from "antd";

const { Title } = Typography;

export default function DailyStakingRedeem() {
  const { dailyRedeem } = useAppSelector((state) => state.general);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    dailyRedeem.forEach((item: any) => {
      data.push({
        _id: item._id,
        user: item.totalUser,
        type: "Total User"
      });
      data.push({
        _id: item._id,
        user: item.totalRedeemAmount.toFixed(2),
        type: "Total Amount (PC)"
      });
    })
  }, [dailyRedeem]);

  return (
    <div className="form-item-container">
      <div style={{ marginBottom: 30 }}>
        <Title level={5}>Daily Staking Redeem</Title>
      </div>
      {
        //@ts-ignore
        <Column {...{
          data: data,
          isGroup: true,
          xField: "_id",
          yField: "user",
          seriesField: 'type',
          colorField: 'type',
          label: {
            position: "middle",
            style: {
              opacity: 0.6,
            },
          },
          xAxis: {
            label: {
              autoHide: true,
              autoRotate: false,
            },
          },
          style: {
            inset: 5
          }
        }} />
      }
    </div>
  );
}
