import request from "../RequestGlobalConfig";

const v1 = {
  withAuthorize: {
    get: function (url: any, params = {}, headers?: any) {
      return request({
        method: "GET",
        url: url,
        params: params,
        headers: headers
          ? headers
          : {
              "Content-type": "application/json",
            },
      });
    },
    post: function (url: any, entity = {}, headers?: any) {
      return request({
        method: "POST",
        url: url,
        responseType: "json",
        headers: headers
          ? headers
          : {
              "Content-type": "application/json",
            },
        data: entity,
      });
    },
    put: function (url: any, entity = {}, headers?: any) {
      return request({
        method: "PUT",
        url: url,
        responseType: "json",
        headers: headers
          ? headers
          : {
              "Content-type": "application/json",
            },
        data: entity,
      });
    },
    delete: function (url: any, entity = {}) {
      return request({
        method: "DELETE",
        url: url,
        responseType: "json",
        headers: {
          "Content-type": "application/json",
        },
        data: entity,
      });
    },
  },
  uploadFile: {
    postFile: function (url: any, entity = {}, settings: any) {
      return request({
        method: "POST",
        url: url,
        responseType: "json",
        data: entity,
        ...settings,
      });
    },
    putFile: function (url: any, entity = {}, settings: any) {
      return request({
        method: "PUT",
        url: url,
        responseType: "json",
        data: entity,
        ...settings,
      });
    },
  },
};

export default v1;
