import { useLocation, Navigate } from "react-router-dom";
import {useAppSelector} from "../../stores";

export default function RequireAuth({ children, roles = [] }: { children: JSX.Element, roles?: string[] }) {
  let location = useLocation();
  const token = localStorage.getItem("AccessToken");
  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
