import React from "react";
import FormSearch from "./FormSearch";
import ListFilter from "./ListFilter";

type PropsType = {
  filter: any;
  onSearch: (params: any) => void;
};

export default function Search(props: PropsType) {
  return (
    <div className="search-container">
      <FormSearch filter={props.filter} onSearch={props.onSearch} />
      <ListFilter filter={props.filter} onSearch={props.onSearch} />
    </div>
  );
}
