import * as R from "ramda";

/* Split Path from string to Array */
const xSplit = (properties: string) => {
  return properties.split(".");
};

export const orArray = (properties: string, record: any) => {
  return R.pathOr([], xSplit(properties))(record);
};

export const orNull = (properties: string, record: any) => {
  return R.pathOr(null, xSplit(properties))(record);
};
export const orString = (properties: string, record: any) => {
  return R.pathOr("", xSplit(properties))(record);
};

export const orNewDate = (properties: string, record: any) => {
  return R.pathOr(new Date(), xSplit(properties))(record);
};

export const orObject = (properties: string, record: any) => {
  return R.pathOr({}, xSplit(properties))(record);
};

export const orNumber = (properties: string, record: any) => {
  return R.pathOr(0, xSplit(properties))(record);
};

export const orNow = (properties: string, record: any) => {
  return R.pathOr(new Date(), xSplit(properties))(record);
};

/* Find Index From Array With Deep Path */
const deepIndex = (properties: string, array: any, value: any) => {
  const isCorrect = R.pathEq(xSplit(properties), value);

  return R.findIndex(isCorrect, array);
};

/* Get Array and CurrentIndex From Record */
const indexFromArray = (
  properties: string,
  deepPath: any,
  value: any,
  record: any
) => {
  const array = orArray(properties, record);

  const index = deepIndex(deepPath, array, value);

  return { index, array };
};

export const construct = R.construct;

export const orEmpty = (properties: string, record: any) => {
  return R.pathOr("", xSplit(properties))(record);
};

export const orErrorType = (properties: string, record: any) => {
  return R.pathOr("error", xSplit(properties))(record);
};

export const orBoolean = (properties: string, record: any) => {
  return R.pathOr(false, xSplit(properties))(record);
};

export const updateString = (properties: string, value: any, record: any) => {
  return R.assocPath(xSplit(properties), R.or(value, ""), record);
};

export const updateStringOrNull = (
  properties: string,
  value: any,
  record: any
) => {
  return R.assocPath(xSplit(properties), R.or(value, null), record);
};

export const updateNumber = (properties: string, value: any, record: any) => {
  return R.assocPath(xSplit(properties), R.or(value, 0), record);
};

export const updateBoolean = (properties: string, value: any, record: any) => {
  return R.assocPath(xSplit(properties), R.or(value, false), record);
};

export const find = (properties: string, value: any, record: any) => {
  return R.find(R.pathEq(xSplit(properties), value), record);
};

export const updateArray = (properties: string, value: any, record: any) => {
  return R.assocPath(xSplit(properties), R.or(value, []), record);
};

export const updateObject = (properties: string, value: any, record: any) => {
  return R.assocPath(xSplit(properties), R.or(value, {}), record);
};

export const prependDataToList = (
  properties: string,
  value: any,
  record: any
) => {
  const array = orArray(properties, record);
  const data = R.prepend(value, array);

  return R.assocPath(xSplit(properties), data, record);
};

export const removeDataFromList = (
  properties: string,
  deepPath: any,
  value: any,
  record: any
) => {
  const { index, array } = indexFromArray(properties, deepPath, value, record);

  const nArray = R.remove(index, 1, array);
  return R.assocPath(xSplit(properties), nArray, record);
};

export const updateDataToList = (
  properties: string,
  deepPath: any,
  data: any,
  record: any
) => {
  const value = orNull(deepPath, data);

  const { index, array } = indexFromArray(properties, deepPath, value, record);

  const newArray = R.update(index, data, array);
  return R.assocPath(xSplit(properties), R.or(newArray, []), record);
};

export const updateDataToObj = (properties: string, data: any, record: any) => {
  return R.assocPath(xSplit(properties), data, record);
};

export const orDefault = (dv: string, properties: string, record: any) => {
  const arrPath = xSplit(properties);

  return R.pathOr(dv, arrPath, record);
};

export const orDefaultValue = (value: string, dv: string) => {
  return R.or(value, dv);
};

export const orPath = (dv: string, properties: any, record: any) => {
  return R.pathOr(dv, properties, record);
};
