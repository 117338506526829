import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "stores";
import Header from "./components/Header";
import { getUserPredictPnl, getUserDetail } from "stores/UserSlice";
import Content from "./components/Content";
export default function DetailUserPage() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const accessToken = useAppSelector((state) => state.profile).token;
  const fetchData = () => {
    if (id && accessToken) {
      // dispatch(getUserPredictPnl(id));
      dispatch(getUserDetail(id));
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, accessToken]);
  return (
    <div>
      <Header />
      <Content />
    </div>
  );
}
