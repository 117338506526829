import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ConnectApi from "apis/ConnectApi";
import CommonSlice from "./CommonSlice";

const initialState = {
  peaceConnect: [] as ConnectType[],
  searchValue: undefined as string | undefined
};

export const getTree = createAsyncThunk(
  "connect/getTree",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    thunkApi.dispatch(ConnectSlice.actions.setPeaceConnect([]));
    try {
      const response = await ConnectApi.connects(params);

      const { data } = response;
      if (data) {
        return data.data as ConnectType[];
      }
      return [];
    } catch (error) {
      return [];
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

const ConnectSlice = createSlice({
  name: "connect",
  initialState,
  reducers: {
    setPeaceConnect: (state, action: PayloadAction<any>) => {
      state.peaceConnect = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<any>) => {
      state.searchValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTree.fulfilled, (state, action) => {
      if (action.payload) {
        state.peaceConnect = action.payload;
      }
    });
  },
});

export default ConnectSlice;
