import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultMeta } from "utils/Mocks/Common";
import CommonSlice from "./CommonSlice";
import UserApi from "apis/UserApi";
import { orArray, orEmpty, orNumber } from "utils/Selector";
const initialState = {
  meta: defaultMeta as MetaType,
  users: [] as UserType[],
  userPredictPnl: null as UserPredictPnlType | null,
  detail: null as UserType | null,
  friendVolume: {
    datas: [] as FriendUserType[],
    meta: null as MetaType | null,
  },
  commissionRebate: {
    datas: [] as CommissionRebase[],
    meta: null as MetaType | null,
  },
  histories: {
    datas: [] as TransactionTotalType[],
    meta: defaultMeta as MetaType,
  },
};

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUsers(params);
      const { data } = response;
      const meta = {
        page: orNumber("data.page", data),
        pageSize: orNumber("data.pageSize", data),
        total: orNumber("data.total", data),
      };
      return {
        meta: meta,
        users: orArray("data.datas", data),
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getUserDetail = createAsyncThunk(
  "users/getUserDetail",
  async (userId: string, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUserDetail(userId);
      const { data } = response;
      return data.data;
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.updateUser(params);
      const { data } = response;
      if (data.meta.status === 200) {
        thunkApi.dispatch(
          CommonSlice.actions.showNotice({
            type: "success",
            message: "Success!",
            description: "Update user successful!",
          })
        );
        return data.data;
      } else {
        thunkApi.dispatch(
          CommonSlice.actions.showNotice({
            type: "error",
            message: "Error!",
            description: orEmpty("meta.externalMessage", data),
          })
        );
      }
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getUserPredictPnl = createAsyncThunk(
  "users/getUserPredictPnl",
  async (userId: string, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUserPredictPnl(userId);
      const { data } = response;
      return orArray("data", data) as UserPredictPnlType[];
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getUserFriendVolume = createAsyncThunk(
  "users/getUserFriendVolume",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUserFriendVolume(params);
      const { data } = response;
      const meta = {
        page: orNumber("data.page", data),
        pageSize: orNumber("data.pageSize", data),
        total: orNumber("data.total", data),
      };
      return {
        meta: meta,
        datas: orArray("data.datas", data),
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getUserHistory = createAsyncThunk(
  "users/getUserHistory",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUserHistoryData(params);
      const { data } = response;
      const meta = {
        page: orNumber("data.page", data),
        pageSize: orNumber("data.pageSize", data),
        total: orNumber("data.total", data),
      };
      return {
        meta: meta,
        datas: orArray("data.datas", data),
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getUserCommissionRebate = createAsyncThunk(
  "users/getUserCommissionRebate",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUserCommissionRebate(params);
      const { data } = response;
      const meta = {
        page: orNumber("data.page", data),
        pageSize: orNumber("data.pageSize", data),
        total: orNumber("data.total", data),
      };
      return {
        meta: meta,
        datas: orArray("data.datas", data),
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getUserPredictHistories = createAsyncThunk(
  "users/getUserPredictHistories",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUserPredictHistories(params);
      const { data } = response;
      const meta = {
        page: orNumber("data.page", data),
        pageSize: orNumber("data.pageSize", data),
        total: orNumber("data.total", data),
      };
      return {
        meta: meta,
        datas: orArray("data.datas", data),
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

const UserSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      if (action.payload) {
        state.meta = action.payload.meta;
        state.users = action.payload.users;
      }
    });
    builder.addCase(getUserPredictPnl.fulfilled, (state, action) => {
      if (action.payload) {
        state.userPredictPnl = action.payload[0];
      }
    });
    builder.addCase(getUserFriendVolume.fulfilled, (state, action) => {
      if (action.payload) {
        state.friendVolume.meta = action.payload.meta;
        state.friendVolume.datas = action.payload.datas;
      }
    });
    builder.addCase(getUserCommissionRebate.fulfilled, (state, action) => {
      if (action.payload) {
        state.commissionRebate.meta = action.payload.meta;
        state.commissionRebate.datas = action.payload.datas;
      }
    });
    builder.addCase(getUserDetail.fulfilled, (state, action) => {
      if (action.payload) {
        state.detail = action.payload;
      }
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.detail = action.payload;
      }
    });
    builder.addCase(getUserHistory.fulfilled, (state, action) => {
      if (action.payload) {
        state.histories.datas = action.payload.datas;
        state.histories.meta = action.payload.meta;
      }
    });
  },
});

export default UserSlice;
