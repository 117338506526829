import { Button } from "antd";
import PageHeader from "components/PageHeader";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  return (
    <PageHeader
      title="Detail user"
      extra={[
        <Button key="1" onClick={() => navigate("/users")}>
          Back
        </Button>,
      ]}
    />
  );
}
