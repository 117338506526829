export const filterOptions = [
  {
    label: "Status Active",
    value: "withStatusActive",
  },
  {
    label: "Status Two Factor",
    value: "withStatus2FA",
  },
  {
    label: "Sponsor",
    value: "withSponsor",
  }
];

export const optionMultiple = [] as string[];

export const ActiveStatus = [
  { label: "Active", value: 1 },
  { label: "Deactive", value: 0 },
];

export const TwoFAStatus = [
  { label: "Enable", value: 1 },
  { label: "Disable", value: 0 },
];
