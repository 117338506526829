import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GeneralApi from "apis/GeneralApi";
import CommonSlice from "./CommonSlice";

const initialState = {
  general: null as GeneralDataType | null,
  adminGeneral: null as any,
  dailyVolume: [] as DailyVolumeType[],
  activeUsers: [] as DailyActiveUserType[],
  dailyRegister: [] as DailyUserRegister[],
  dailyRedeem: [] as any[]
};

export const getStaticGeneral = createAsyncThunk(
  "general/getStaticGeneral",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await GeneralApi.general(params);
      const { data } = response;
      if (data) {
        return data.data as GeneralDataType;
      }
      return null;
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getAdminStaticGeneral = createAsyncThunk(
    "general/getAdminStaticGeneral",
    async (params: any, thunkApi: any) => {
        thunkApi.dispatch(CommonSlice.actions.incrementLoading());
        try {
            const response = await GeneralApi.generalAdmin(params);
            const { data } = response;
            if (data) {
                return data.data as any;
            }
            return null;
        } catch (error) {
            return null;
        } finally {
            thunkApi.dispatch(CommonSlice.actions.decrementLoading());
        }
    }
);

export const getDailyVolume = createAsyncThunk(
  "general/getDailyVolume",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await GeneralApi.dailyVolume(params);
      const { data } = response;
      if (data) {
        return data.data as DailyVolumeType[];
      }
      return null;
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getDailyRegister = createAsyncThunk(
    "general/getDailyRegister",
    async (params: any, thunkApi: any) => {
        thunkApi.dispatch(CommonSlice.actions.incrementLoading());
        try {
            const response = await GeneralApi.dailyRegisterUser(params);
            const { data } = response;
            if (data) {
                return data.data as DailyUserRegister[];
            }
            return null;
        } catch (error) {
            return null;
        } finally {
            thunkApi.dispatch(CommonSlice.actions.decrementLoading());
        }
    }
);

export const getDailyRedeem = createAsyncThunk(
  "general/getDailyRedeem",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await GeneralApi.dailyStakingRedeem(params);
      const { data } = response;
      if (data) {
        return data.data as any[];
      }
      return null;
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getDailyActiveUser = createAsyncThunk(
  "general/getDailyActiveUser",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await GeneralApi.dailyActiveUser(params);
      const { data } = response;
      if (data) {
        return data.data as DailyActiveUserType[];
      }
      return null;
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

const GeneralSlice = createSlice({
  name: "general",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStaticGeneral.fulfilled, (state, action) => {
      if (action.payload) {
        state.general = action.payload;
      }
    });
      builder.addCase(getAdminStaticGeneral.fulfilled, (state, action) => {
          if (action.payload) {
              state.adminGeneral = action.payload;
          }
      });
    builder.addCase(getDailyVolume.fulfilled, (state, action) => {
      if (action.payload) {
        state.dailyVolume = action.payload;
      }
    });
      builder.addCase(getDailyRegister.fulfilled, (state, action) => {
          if (action.payload) {
              state.dailyRegister = action.payload;
          }
      });
    builder.addCase(getDailyRedeem.fulfilled, (state, action) => {
      if (action.payload) {
        state.dailyRedeem = action.payload;
      }
    });
    builder.addCase(getDailyActiveUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.activeUsers = action.payload;
      }
    });
  },
});

export default GeneralSlice;
